
import React, { useEffect } from 'react'
import '../../fonts/fonts.css'
import { useParams } from "react-router-dom";
import arrow from "../../imagesECF/EcfEvalBullet.jpeg"
import header from "../../imagesECF/EcfEvalHeader.jpeg"

export default function PrinterEval() {
    var { display, lastname, firstname, email, license, totalscore, hours, glasses, score1, score2, score3, score4, score5, score6, score7, autorisation, maxscore1, maxscore2, maxscore3, maxscore4, maxscore5, maxscore6, maxscore7 } = useParams();
    if (autorisation == null) {
        autorisation = ''
    }
    if (maxscore1 == null)
    {
        maxscore1 = 21
        maxscore2 = 4
        maxscore3 = 6
        maxscore4 = 24
        maxscore5 = 21
        maxscore6 = 20
        maxscore7 = 4
    }
    useEffect(() => { setTimeout(() => window.print(), 1000) }
    );
    return (
        <html><head><title>Résultat Eval'ECF</title></head><body>
            <table style={{ width: "600px", marginright: "auto", marginLeft: "auto" }}>
            </table>
            <table style={{ width: "590px", backgroundColor: "#fff", marginRight: "auto", marginLeft: "auto", padding: "20px 20px 40px", fontFamily: "Arial Verdana sans-serif" }}>
                <thead>
                    <tr>
                        <td>
                            <img alt="" border="1" class="rnb-col-1-img" hspace="0" src={header} style={{ verticalAlign: "top", marginRight: "auto", marginBottom: "3%", marginLeft: "auto", width: "100%" }} vspace="0" /></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div style={{ width: "60%", display: "inline-block" }}>
                                <p style={{ fontSize: "12px", fontFamily: "Arial" }}>
                                    Un(e) de vos élèves vient de terminer son évaluation initiale. Vous trouverez ci-dessous les informations relatives à son score :</p>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ fontFamily: "Arial", marginBottom: "5px", width: "100%", height: "90px", border: "2px solid #00a1e1", borderRadius: "7px", marginTop: "2%" }}>
                                <ul style={{ fontSize: "16px", paddingLeft: "40px", listStyle: "none", marginTop: "10px", lineHeight: "18px" }}>
                                    <li>
                                        <span style={{ fontStyle: "italic" }}>Nom :</span>&nbsp;<span style={{ textTransform: "uppercase", fontWeight: "bold", color: " #00a1e1" }}>{lastname}</span></li>
                                    <li>
                                        <span style={{ fontStyle: "italic" }}>Prénom :</span>&nbsp;<span style={{ fontWeight: "bold", color: "#00a1e1" }}>{firstname}</span></li>
                                    <li>
                                        <span style={{ fontStyle: "italic" }}>E-mail :</span> <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{email}</span></li>
                                    <li>
                                        <span style={{ fontStyle: "italic" }}>Permis : </span><span style={{ fontWeight: "bold", color: "#00a1e1" }}>{license}</span></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: "5px", width: "100%", height: "30px", border: "2px solid #000000", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ fontFamily: "Arial", width: "330px", fontSize: "16px", paddingLeft: "25px", marginTop: "5px", height: "30px", float: "left" }}>
                                    <img alt="" src={arrow} style={{ marginRight: "15px" }} />
                                    Expérience de la conduite
                                </span>
                                <span style={{ fontFamily: "Arial", height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "4px", marginRight: "10px" }}>
                                    <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{score1}&nbsp;</span>
                                    /&nbsp;{maxscore1}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: "5px", fontFamily: "Arial", width: "100%", height: "30px", border: "2px solid #000000", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ width: "330px", fontSize: "16px", paddingLeft: "25px", marginTop: "5px", height: "30px", float: "left"}}>
                                    <img alt="" src={arrow} style={{ marginRight: "15px" }} />
                                    Connaissance du véhicule
                                </span>
                                <span style={{ fontFamily: "Arial", height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "4px", marginRight: "10px" }}>
                                    <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{score2}&nbsp;</span>
                                    /&nbsp;{maxscore2}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: "5px", fontFamily: "Arial", width: "100%", height: "30px", border: "2px solid #000000", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ width: "500px", fontSize: "16px", paddingLeft: "25px", marginTop: "5px", height: "30px", float: "left" }}>
                                    <img alt="" src={arrow} style={{ marginRight: "15px" }} />
                                    Attitude à l'égard de la conduite et de la sécurité
                                </span>
                                <span style={{ fontFamily: "Arial", height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "4px", marginRight: "10px" }}>
                                    <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{score3}&nbsp;</span>
                                    /&nbsp;{maxscore3}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: "5px", fontFamily: "Arial", width: "100%", height: "30px", border: "2px solid #000000", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ width: "330px", fontSize: "16px", paddingLeft: "25px", marginTop: "5px", height: "30px", float: "left" }}>
                                    <img alt="" src={arrow} style={{ marginRight: "15px" }} />
                                    Habileté
                                </span>
                                <span style={{ fontFamily: "Arial", height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "4px", marginRight: "10px" }}>
                                    <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{score4}&nbsp;</span>
                                    /&nbsp;{maxscore4}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: "5px", fontFamily: "Arial", width: "100%", height: "30px", border: "2px solid #000000", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ width: "330px", fontSize: "16px", paddingLeft: "25px", marginTop: "5px", height: "30px", float: "left" }}>
                                    <img alt="" src={arrow} style={{ marginRight: "15px" }} />
                                    Compréhension et mémoire
                                </span>
                                <span style={{ fontFamily: "Arial", height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "4px", marginRight: "10px" }}>
                                    <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{score5}&nbsp;</span>
                                    /&nbsp;{maxscore5}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: "5px", fontFamily: "Arial", width: "100%", height: "30px", border: "2px solid #000000", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ width: "330px", fontSize: "16px", paddingLeft: "25px", marginTop: "5px", height: "30px", float: "left" }}>
                                    <img alt="" src={arrow} style={{ marginRight: "15px" }} />
                                    Perception
                                </span>
                                <span style={{ fontFamily: "Arial", height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "4px", marginRight: "10px" }}>
                                    <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{score6}&nbsp;</span>
                                    /&nbsp;{maxscore6}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: "5px", fontFamily: "Arial", width: "100%", height: "30px", border: "2px solid #000000", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ width: "330px", fontSize: "16px", paddingLeft: "25px", marginTop: "5px", height: "30px", float: "left" }}>
                                    <img alt="" src={arrow} style={{ marginRight: "15px" }} />
                                    Emotivité
                                </span>
                                <span style={{ fontFamily: "Arial", height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "4px", marginRight: "10px" }}>
                                    <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{score7}&nbsp;</span>
                                    /&nbsp;{maxscore7} 
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ width: "100%", height: "60px", border: "2px solid #00a1e1", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ fontFamily: "Arial", height: "30px", float: "left", fontWeight: "bold", fontSize: "25px", color: "#00a1e1", paddingLeft: "40px", marginTop: "15px" }}>Score total</span> <span style={{ fontFamily: "Arial", width: "156px", height: "60px", textAlign: "center", float: "right", marginTop: "5px", marginRight: "10px" }}><span style={{ fontFamily: "Arial", display: "inlineBlock", color: "#00a1e1", marginTop: "5px", fontSize: "29px" }}><span style={{ fontSize: "45px", fontWeight: "bold" }}>{totalscore}</span>/100</span>
                                </span></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: "5px", fontFamily: "Arial", width: "100%", height: "30px", border: "2px solid #000000", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                <span style={{ width: "400px", display: "inline-block", fontSize: "18px", color: "#000000", paddingLeft: "25px", marginTop: "2px" }}>
                                    <img alt="" src={arrow} style={{ marginRight: "15px" }} />
                                    Port de lunettes ou lentilles de contact
                                </span>
                                <span style={{ height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "2px", marginRight: "10px" }}>
                                    <span style={{ fontWeight: "bold", color: "#00a1e1" }}>{glasses}&nbsp;</span>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {
                                display === 'none'
                                ? null
                                : <div style={{ marginBottom: "5px", fontFamily: "Arial", width: "100%", height: "30px", border: "2px solid #00a1e1", borderRadius: "7px", marginTop: "2px", position: "relative" }}>
                                    <span style={{ paddingLeft: "20px", height: "30px", float: "right", display: "table", textAlign: "center", fontSize: "20px", marginTop: "2px", marginRight: "10px" }}>
                                        <span style={{ fontWeight: "bold", color: "#00a1e1" }}>Volume de formation proposé: {hours} {display === 'seq' ? 'séquences' : 'heures'}&nbsp;</span>
                                    </span>
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ fontFamily: "Arial", marginBottom: "5px", width: "260px", height: "200px", border: "2px solid #000000", borderRadius: "10px", marginTop: "2px", display: "inline-block" }}>
                                <p style={{ marginTop: "10px", marginLeft: "15px", color: "#000000" }}>
                                    Cachet de l’agence :</p>
                                <p style={{ marginTop: "80px", marginLeft: "15px", color: "#000000" }}>
                                    Nom du formateur :</p>
                                <p style={{ marginTop: "10px", marginLeft: "15px", color: "#000000" }}>
                                    N° d'autorisation d'enseigner :</p>
                                    <span style={{ fontWeight: "bold",marginLeft: "15px", color: "black" }}>{autorisation}</span>
                            </div>
                            <div style={{ fontFamily: "Arial", width: "260px", height: "200px", border: "2px solid #000000", borderRadius: "10px", marginTop: "2px", display: "inline-block", float: "right" }}>
                                <p style={{ marginTop: "10px", marginLeft: "15px", color: "#000000" }}>
                                    Date et signature de l’élève :</p>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <img width="1" height="1" src="https://bdhfahj.r.af.d.sendibt2.com/tr/op/qXrVgAuvLqQCtbSzHPW9yFsxEPTSru4KPDNf1LG3gjoHo57chotx89DpzFj0PkMfXgx9eMzOIV0kpse5aUhTi2KI77PdCeKO0qenj2x5jYdTtqObE8yUmUi40-pomlrv-EHF4xLqrXGQ3jEIm3FLW8f-GfeQ4zVSI-yzPTX8qBvU" alt="" />

        </body></html>
    );
}