import React, { useState } from 'react'
import '../../fonts/fonts.css'
import UiLogout from '../../images/UiLogout.svg'
import UiEleveActive from '../../images/UiEleve2.svg'
import UiEleveInactive from '../../images/UiEleveInactive.svg'
import UiAddActive from '../../images/Uiadd.svg'
import UiAddInactiveActive from '../../images/UiAddInactive.svg'
import UiBaremArrowActive from '../../images/UiBaremArrow.svg'
import UIBaremArrowInactive from '../../images/UiBaremArrowInactive.svg'
import UiUser from '../../images/accountwhite.svg'
import UiCarActive from '../../images/UiAgences.svg'
import UiCarInactive from '../../images/Car_white.svg'
import UiArchiveActive from '../../images/UiArchiveActive.svg'
import UiArchiveInactive from '../../images/UiArchiveInactive.svg'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import { logoutreset } from '../App/actions'
import { Link, withRouter, useLocation } from 'react-router-dom'
import Button from '../../components/Button/index'
import ModalConfirm from '../../components/ModalConfirm/index'
import config from '../../config/configurator'
import ElearningLogo from '../../images/elearningLogo.svg'
import MLLogo from '../../imagesMissionLocale/MLLOGO.png'
import ECFLogo from '../../imagesECF/Logo.png'
import VinciLogo from '../../imagesVinci/LOGO_ASF.svg'
import PPLogo from '../../imagesPP/PPLogo.png'
import { LeftBar, SideLogo, Text, AgencyName, AdminContainer, Admin, ButtonContainer, SpaceBetween, User, UserBloc, UserImg, ButtonLogOut, LogoutImg, LogoutText, Contact } from './styledComponents'

const SideBar = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)
  const agencyname = useSelector((state) => state.toJS().global.agencyName)
  const pathname = location.pathname

  const [show, setShow] = useState(false)

  const toggleDisconnect = () => {
    setShow(!show)
  }

  const disconnect = () => {
    dispatch(logoutreset())
    window.location = process.env.REACT_APP_SERVER_CLIENT
  }

  return (
    <div>
      <ModalConfirm
        show={show}
        text='Vous allez vous déconnecter'
        confirm={() => disconnect()} icon={UiLogout} onClick={() => toggleDisconnect()}
        confirmText='Se déconnecter'
        cancelText='rester connecté'
        cancel={() => setShow(false)}
      />
      <LeftBar>
        <Link to={config.home}>
          <SideLogo src={config.title === 'ECF' ? ECFLogo : config.title === 'ML ADMIN' ? MLLogo : ElearningLogo && config.title === 'VINCI' ? VinciLogo : config.title === 'PP ADMIN' ? PPLogo : '' } />
        </Link>
        <Text>Console de gestion</Text>
        <AdminContainer style={{ opacity: isAdmin ? 1 : 0 }}>
          <Admin>Admin</Admin>
        </AdminContainer>
        <ButtonContainer>
          {config.features.elearning === true ? <Button active={pathname === '/players' || false} icon={pathname === '/players' ? UiEleveActive : UiEleveInactive} to='/players' text='Les élèves' /> : null}
          {config.features.elearning === true && !isAdmin ? <Button active={pathname === '/players/create' || false} icon={pathname === '/players/create' ? UiAddActive : UiAddInactiveActive} to='/players/create' text='Créer un élève' /> : config.features.simu === true ? <Button active={pathname === '/simuplayers' || false} icon={pathname === '/simuplayers' ? UiEleveActive : UiEleveInactive} to='/simuplayers' text='Les élèves' /> : null}
          {config.features.simu === true && (config.title === ('ECF' && !isAdmin) || ('PP ADMIN' && !isAdmin)) ? <Button active={pathname === '/simuplayers/create' || false} icon={pathname === '/simuplayers/create' ? UiAddActive : UiAddInactiveActive} to='/simuplayers/create' text='Créer un élève' /> : null}
          <SpaceBetween />
          {isAdmin ? <Button active={pathname === '/agencies' || false} icon={pathname === '/agencies' ? UiCarActive : UiCarInactive} to='/agencies' text='Les agences' /> : null}
          {isAdmin ? <Button active={pathname === '/agency/create' || false} icon={pathname === '/agency/create' ? UiAddActive : UiAddInactiveActive} to='/agency/create' text='Créer une agence' /> : null}
          <SpaceBetween />
          {config.features.simu === true && config.features.eval === true && !isAdmin ? <Button icon={pathname === '/scaleeval/tgd1' || pathname === '/scaleeval/tgd2' ? UiBaremArrowActive : UIBaremArrowInactive} active={pathname === '/scaleeval/tgd1' || pathname === '/scaleeval/tgd2' || false} to='/scaleeval/tgd1' text='Barème Eval' /> : null}
          {config.features.simu === true ? <Button icon={pathname === '/simuplayers/archive' ? UiArchiveActive : UiArchiveInactive} active={pathname === '/simuplayers/archive' || false} to='/simuplayers/archive' text={'Élèves clôturés'} /> : null}

          {/* {config.features.simu === true ? <ButtonUnused color={'grey'}  icon={arrowpush} to='/Downloads' text={'Téléchargements'} /> : null} */}
          <SpaceBetween />
          {/* {config.features.simu === true ? <Button  icon={UiActu} to='/News' text={'Actualités'} /> : null} */}
          {/* {config.features.simu === true ? <Button  icon={UiAccount} to='/AgencyProfile' text={'Profil Agence'} /> : null} */}
          <UserBloc style={{ bottom: config.features.simu === true ? '12%' : '7%' }}>
            <User>
              <UserImg src={UiUser} />
              <AgencyName>{agencyname}</AgencyName>
            </User>
            <ButtonLogOut onClick={() => toggleDisconnect()}>
              <LogoutImg src={UiLogout} />
              <LogoutText>Se déconnecter</LogoutText>
            </ButtonLogOut>
          </UserBloc>
        </ButtonContainer>
        {config.features.simu === true
          ? <Contact />
          : null}
      </LeftBar>
    </div>
  )
}

const withConnect = connect(null, null)
const arg = compose(withConnect)(SideBar)

export default withRouter(arg)
